import Vue from 'vue';

export default {
    recuperarTiposRelatoriosDisponiveis(processoOid) {
        return Vue.axios.get('relatorio/tiposRelatorio/'+ processoOid);
    },

    gerarRelatorioatorioCSV(relatorioRequest) {
        return Vue.axios.post('relatorio/csv', relatorioRequest, {
            responseType: "arraybuffer",
            headers: {
                'Accept': "text/csv"
            }
        });
    }

}
