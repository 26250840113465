<template>
  <v-card>
    <v-card-title class="justify-center mb-3 titulo">
      <h2>
        Relatórios
      </h2>
    </v-card-title>
    <v-card-text v-if="loaded">
      <v-row>
        <v-col cols="6">
          <v-autocomplete
              v-model="processoSelecionado"
              :items="processos"
              outlined
              label="Selecione um processo"
              placeholder="Selecione um processo"
              item-text="nome"
              item-value="oid"
              return-object
          />
        </v-col>
        <v-col cols="6" :key="processoSelecionado && processoSelecionado.oid">
          <v-select
              v-model="tipoRelatorioSelecionado"
              :items="tiposRelatorioDisponiveis"
              label="Selecione um relatório"
              placeholder="Selecione um relatório"
              item-text="nomeRelatorio"
              item-value="nomeArquivo"
              outlined
              :disabled="!processoSelecionado"
              return-object
          />
          <v-select
              v-if="tipoRelatorioSelecionado && tipoRelatorioSelecionado.geravelEmBranco"
              v-model="emBranco"
              :items="opcoesEmBranco"
              label="Gerar relatório em branco"
              placeholder="Selecione uma opção"
              item-text="text"
              item-value="value"
              outlined
              :disabled="!processoSelecionado && !tipoRelatorioSelecionado"
          />
        </v-col>
      </v-row>
      <v-row v-if="!emBranco || !tipoRelatorioSelecionado.geravelEmBranco">
        <v-card-text v-if="tipoRelatorioSelecionado">
          <div v-if="tipoRelatorioSelecionado.filtraPorCurso"
               :key="processoSelecionado.oid"
          >
            <v-row justify="center">
              <v-alert type="warning" outlined
                       v-if="tipoRelatorioSelecionado.nomeArquivo === 'relatorio_necessidades_especiais'">
                {{
                  opcoesNecessidadeEspecialFiltro.length ? 'Somente as opções abaixo possuem candidatos com necessidades especiais.'
                      : 'Não há opções cujo os candidatos possuam necessidades especiais'
                }}
              </v-alert>
            </v-row>
            <v-row v-if="tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao' && opcoesSemAvaliadorQuestao.length" justify="center">
              <v-alert type="warning" outlined text>
                Atenção! Há opções cuja quantidade de questões e/ou quantidade de avaliadores está cadastrada como 0.
              </v-alert>
            </v-row>
            <v-row v-if="opcoesNecessidadeEspecialFiltro.length">
              <v-col cols="12" class="d-flex justify-space-between">
                <span class="font-weight-bold ml-3 mb-n2 mt-2" style="font-size: 15pt">Opções</span>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" large v-bind="attrs" icon class="mr-1" @click="checkOpcoes">
                        <v-icon>
                          {{ opcoesSelecionadas.length ? opcoesSelecionadas.length === (opcoesNecessidadeEspecialFiltro.length - opcoesSemAvaliadorQuestao.length ) ? 'mdi-checkbox-outline' : 'mdi-minus-box-outline' : 'mdi-checkbox-blank-outline' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ opcoesSelecionadas.length ? 'Desmarcar todas as opções' : 'Marcar todas as opções' }}
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card
                    elevation="1"
                >
                  <v-list max-height="400" class="overflow-y-auto" ref="listaOpcoes">
                    <v-list-item-group
                        v-model="opcoesSelecionadas"
                        active-class="primary--text"
                        multiple
                    >
                      <template v-for="(item, index) in opcoesNecessidadeEspecialFiltro">
                        <v-list-item :key="index" :disabled="tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao' && !item.possuiAvaliadoresEQuestoes">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.nomeOpcao"></v-list-item-title>
                              <v-list-item-subtitle>
                                <div v-if="tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao'">
                                  <span :style="`color: ${item.possuiAvaliadoresEQuestoes ? 'black' : 'darkred'}`">
                                    Avaliadores: {{ item.quantidadeAvaliadores }} - Questões: {{ item.quantidadeQuestoes }}
                                  </span>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                  v-if="active"
                                  color="yellow darken-3"
                              >
                                mdi-check-circle
                              </v-icon>
                              <v-icon
                                  v-else
                                  color="grey lighten-1"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < opcoes.length - 1"
                            :key="index.oid"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-if="tipoRelatorioSelecionado.filtraPorLocal"
               :key="processoSelecionado.oid"
          >
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <span class="font-weight-bold ml-3 mb-n2 mt-2" style="font-size: 15pt">Locais</span>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-on="on" large v-bind="attrs" icon class="mr-1" @click="checkLocais">
                        <v-icon>
                          {{ locaisSelecionados.length ? locaisSelecionados.length === locaisProva.length ? 'mdi-checkbox-outline' : 'mdi-minus-box-outline' : 'mdi-checkbox-blank-outline' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ locaisSelecionados.length ? 'Desmarcar todos os locais' : 'Marcar todos os locais' }}
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card
                    elevation="1"
                >
                  <v-list max-height="400" class="overflow-y-auto">
                    <v-list-item-group
                        v-model="locaisSelecionados"
                        active-class="primary--text"
                        multiple
                    >
                      <template v-for="(item, index) in locaisProva">
                        <v-list-item :key="index">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.nome"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                  v-if="active"
                                  color="yellow darken-3"
                              >
                                mdi-check-circle
                              </v-icon>
                              <v-icon
                                  v-else
                                  color="grey lighten-1"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>

                        <v-divider
                            v-if="index < locaisProva.length - 1"
                            :key="index.oid"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-spacer/>
      <v-btn width="100" color="primary" @click="gerarRelatorio" :disabled="!tipoRelatorioSelecionado">
        Gerar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import processoService from "@/services/processo.service";
import localProvaService from "@/services/localProva.service";
import opcaoService from "@/services/opcao.service";
import relatorioService from "@/services/relatorio.service";

export default {
  name: "Relatórios",
  components: {},


  data() {
    return {
      processos: [],
      tiposRelatorio: [],
      processoSelecionado: null,
      loaded: false,
      loadTiposRelatorios: false,
      tipoRelatorioSelecionado: null,
      opcoes: [],
      opcoesSelecionadas: [],
      locaisProva: [],
      locaisSelecionados: [],
      emBranco: false,
      opcoesEmBranco: [
        {value: true, text: "Sim"},
        {value: false, text: "Não"}
      ]
    }
  },

  async created() {
    let loader = this.$loading.show();

    await processoService.recuperarProcessosRelatorio().then(response => {
      this.processos = response.data;
    });

    this.loaded = true;
    loader.hide();
  },

  computed: {
    opcoesNecessidadeEspecialFiltro() {
      if (this.tipoRelatorioSelecionado.nomeArquivo === "relatorio_necessidades_especiais") {
        return this.opcoes.filter(o => o.possuiCandidatoNecessidadeEspecial);
      }
      return this.opcoes;
    },

    opcoesSemAvaliadorQuestao() {
      if (this.tipoRelatorioSelecionado.nomeArquivo === "folha_avaliacao") {
        return this.opcoes.filter(o => !o.possuiAvaliadoresEQuestoes);
      }
      return [];
    },
    tiposRelatorioDisponiveis() {
      return this.tiposRelatorio.filter(t => !t.materialAplicacao);
    }
  },

  watch: {
    processoSelecionado: {
      async handler() {
        if (this.processoSelecionado) {
          this.opcoes = [];
          this.locaisProva = [];
          this.opcoesSelecionadas = [];
          this.locaisSelecionados = [];

          await relatorioService.recuperarTiposRelatoriosDisponiveis(this.processoSelecionado.oid).then(response => {
            this.tiposRelatorio = response.data;
          });
          await localProvaService.recuperarLocaisProvaPorProcesso(this.processoSelecionado.oid).then(response => {
            this.locaisProva = response.data;
          });
          await opcaoService.recuperarOpcoesRelatorioPorProcesso(this.processoSelecionado.oid).then(response => {
            this.opcoes = response.data;
          });

          this.loadTiposRelatorios = true;
        }
      },
      deep: true
    },

    tipoRelatorioSelecionado: {
      handler() {
        if (this.tipoRelatorioSelecionado) {
          this.locaisSelecionados = [];
          this.opcoesSelecionadas = [];
        }
      },
      deep: true
    }
  },

  methods: {
    async gerarRelatorio() {
      let loader = this.$loading.show();

      let relatorioRequest = {
        processoOid: this.processoSelecionado.oid,
        opcoes: this.opcoes.filter((o, index) => this.opcoesSelecionadas.includes(index)),
        locais: this.locaisProva.filter((l, index) => this.locaisSelecionados.includes(index)),
        tipoRelatorio: this.tipoRelatorioSelecionado,
        emBranco: this.emBranco
      }

      await relatorioService.gerarRelatorioatorioCSV(relatorioRequest).then(response => {
          this.downloadRelatorio("text/csv", ".csv", response.data);
        });


      loader.hide();
    },

    downloadRelatorio(tipo, extensao, arquivo) {
      const file = new Blob([arquivo], {type: tipo});
      const fileURL = URL.createObjectURL(file);
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.download = this.tipoRelatorioSelecionado.nomeArquivo + '_' + this.processoSelecionado.nome.replace(/\s/g, '_').toLowerCase() + extensao;
      fileLink.click();
    },

    checkOpcoes() {
      if (!this.opcoesSelecionadas.length) {
        if (this.tipoRelatorioSelecionado.nomeArquivo === 'folha_avaliacao') {
          this.opcoes.forEach((i, index) => {
            if (!this.opcoesSemAvaliadorQuestao.includes(i)) {
              this.opcoesSelecionadas.push(index)
            }
          });
        } else {
          this.opcoes.forEach((i, index) => this.opcoesSelecionadas.push(index));
        }
      } else {
        this.desmarcarTodos();
      }
    },

    checkLocais() {
      if (!this.locaisSelecionados.length) {
        this.locaisProva.forEach((l, index) => this.locaisSelecionados.push(index));
      } else {
        this.desmarcarTodos();
      }
    },

    desmarcarTodos() {
      this.locaisSelecionados = [];
      this.opcoesSelecionadas = [];
    }
  }
}
</script>

<style scoped>

</style>